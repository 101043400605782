import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BordasKiegyenlitoLemezComponent } from './bordas-kiegyenlito-lemez/bordas-kiegyenlito-lemez.component';
import { BordasUtofeszitettLemezComponent } from './bordas-utofeszitett-lemez/bordas-utofeszitett-lemez.component';
import { BuszobolComponent } from './buszobol/buszobol.component';
import { EgyebMunkakComponent } from './egyeb-munkak/egyeb-munkak.component';
import { ImpresszumComponent } from './impresszum/impresszum.component';
import { KeretelemComponent } from './keretelem/keretelem.component';
import { KezdolapComponent } from './kezdolap/kezdolap.component';
import { PeronelemComponent } from './peronelem/peronelem.component';
import { VasutiUtatjaroComponent } from './vasuti-utatjaro/vasuti-utatjaro.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BordasKiegyenlitoLemezComponent,
    BordasUtofeszitettLemezComponent,
    BuszobolComponent,
    EgyebMunkakComponent,
    ImpresszumComponent,
    KeretelemComponent,
    KezdolapComponent,
    PeronelemComponent,
    VasutiUtatjaroComponent,
  ],
})
export class PagesModule {}
