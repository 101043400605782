<footer class="footer mat-elevation-z2">
  <nav class="footer-nav">
    <div class="footer-links">
      <a routerLink="/kezdolap" routerLinkActive="active-link" title="Rail System Kezdőlap">
        <span>Rail System</span>
      </a>
      <a routerLink="/vasuti-utatjaro" routerLinkActive="active-link" title="Vasúti útátjáró">
        <span i18n="@@vasuti-utatjaro">{{ 'GLOBAL.VASUTI-UTATJARO' | translate }}</span>
      </a>
      <a routerLink="/keretelem" routerLinkActive="active-link" title="Keretelem">
        <span i18n="@@keretelem">{{ 'GLOBAL.KERETELEM' | translate }}</span>
      </a>
      <a routerLink="/bordas-utofeszitett-lemez" routerLinkActive="active-link" title="Bordás utófeszített lemez">
        <span i18n="@@bordas-utofeszitett-lemez">{{ 'GLOBAL.BORDAS-UTOFESZITETT-LEMEZ' | translate }}</span>
      </a>
      <a routerLink="/buszobol" routerLinkActive="active-link" title="Buszöböl">
        <span i18n="@@buszobol">{{ 'GLOBAL.BUSZOBOL' | translate }}</span>
      </a>
      <a routerLink="/bordas-kiegyenlito-lemez" routerLinkActive="active-link" title="Bordás utófeszített lemez">
        <span i18n="@@bordas-kiegyenliito-lemez">{{ 'GLOBAL.BORDAS-KIEGYENLIITO-LEMEZ' | translate }}</span>
      </a>
      <a routerLink="/peronelem" routerLinkActive="active-link" title="Peronelem">
        <span i18n="@@peronelem">{{ 'GLOBAL.PERONELEM' | translate }}</span>
      </a>
      <a routerLink="/egyeb-munkak" routerLinkActive="active-link" title="Egyéb munkák">
        <span i18n="@@egyeb-munkak">{{ 'GLOBAL.EGYEB-MUNKAK' | translate }}</span>
      </a>
      <a routerLink="/impresszum" routerLinkActive="active-link" title="Impresszum">
        <span i18n="@@impresszum">{{ 'GLOBAL.IMPRESSZUM' | translate }}</span>
      </a>
    </div>
  </nav>

  <div class="name-cards">
    <div class="name-card">
      <p i18n>{{ 'GLOBAL.BELFOLD' | translate }}</p>
      <h4 i18n>{{ 'GLOBAL.KAPCSOLAT-01' | translate }}</h4>
      <p i18n>{{ 'GLOBAL.KAPCSOLAT-01-01' | translate }}</p>
      <br />
      <p>+36 70 9 414 898</p>
      <p>+36 30 8 205 781</p>
      <p i18n>{{ 'GLOBAL.EMAIL-01' | translate }}</p>
    </div>

    <div class="name-card">
      <p i18n>{{ 'GLOBAL.KULFOLD' | translate }}</p>
      <h4 i18n>{{ 'GLOBAL.KAPCSOLAT-02' | translate }}</h4>
      <p i18n>{{ 'GLOBAL.KAPCSOLAT-02-02' | translate }}</p>
      <br />
      <p>+36 70 706 8960</p>
      <p i18n>{{ 'GLOBAL.EMAIL-02' | translate }}</p>
    </div>
  </div>

  <div class="copywrite">
    <p>
      Copyright &copy; 2012-2023 |
      <a href="https://railsystem.hu" title="Rail System Kezdőlap">Rail System </a>
      | All Rights Reserved.
    </p>
    <p>
      design by
      <a href="https://www.marklekin.com" title="Homepage of marklekin"> marklekin </a>
    </p>
  </div>
</footer>
