<rs-image-header
  [headerTitle]="'GLOBAL.VASUTI-UTATJARO' | translate"
  [imageTitle]="'Vasúti útátjáró'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_00.jpg'"
  [linkSubheader]="''"
  [subHeaderTitle]="'Rail System & edilon)(sedra'"
>
</rs-image-header>

<rs-divider [useImage]="false"></rs-divider>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center" mat-stretch-tabs="false" class="mat-elevation-z2">
  <mat-tab [label]="'VASUTI-UTATJARO.EDILON-00' | translate" i18n="@@VASUTI-UTATJARO.EDILON-00">
    <div class="tab-section">
      <div class="tab-block">
        {{ 'VASUTI-UTATJARO.EDILON-01' | translate }}
      </div>
      <br />
      <div class="tab-block">
        {{ 'VASUTI-UTATJARO.EDILON-02' | translate }}
      </div>
    </div>
    <br />
    <div class="tab-section">
      <div class="tab-block">
        {{ 'VASUTI-UTATJARO.EDILON-03' | translate }}
      </div>
      <br />
      <div class="tab-block">
        {{ 'VASUTI-UTATJARO.EDILON-04' | translate }}
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'VASUTI-UTATJARO.EDILON-00' | translate" i18n="@@VASUTI-UTATJARO.EDILON-00">
    <div class="tab-section">
      <div class="tab-block">
        {{ 'VASUTI-UTATJARO.RAIL-SYSTEM-01' | translate }}
      </div>
      <br />
      <div class="tab-block">
        {{ 'VASUTI-UTATJARO.RAIL-SYSTEM-02' | translate }}
      </div>
    </div>
    <br />
    <div class="tab-section">
      <div class="tab-block">
        {{ 'VASUTI-UTATJARO.RAIL-SYSTEM-03' | translate }}
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.ELONYEI' | translate" i18n="@@elonyei">
    <div class="tab-section">
      <div class="tab-block">
        <h4>{{ 'VASUTI-UTATJARO.ELONYEI-00' | translate }}</h4>
        <p>{{ 'VASUTI-UTATJARO.ELONYEI-01' | translate }}</p>
      </div>
      <div class="tab-block">
        <h4>{{ 'VASUTI-UTATJARO.ELONYEI-02' | translate }}</h4>
        <p>{{ 'VASUTI-UTATJARO.ELONYEI-03' | translate }}</p>
      </div>
    </div>
    <div class="tab-section">
      <div class="tab-block">
        <h4>{{ 'VASUTI-UTATJARO.ELONYEI-04' | translate }}</h4>
        <p>{{ 'VASUTI-UTATJARO.ELONYEI-05' | translate }}</p>
      </div>
      <div class="tab-block">
        <h4>{{ 'VASUTI-UTATJARO.ELONYEI-06' | translate }}</h4>
        <p>{{ 'VASUTI-UTATJARO.ELONYEI-07' | translate }}</p>
      </div>
    </div>
    <div class="tab-section">
      <div class="tab-block">
        <h4>{{ 'VASUTI-UTATJARO.ELONYEI-08' | translate }}</h4>
        <p>{{ 'VASUTI-UTATJARO.ELONYEI-09' | translate }}</p>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.PARTNEREK' | translate" i18n="@@partnerek">
    <div class="partner-logo">
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_ferrobeton.jpg'"
        [imageTitle]="'Ferrobeton Zrt.'"
        [route]="'https://ferrobeton.hu'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_normalnyomtav.jpg'"
        [imageTitle]="'Normálnyomtáv Kft.'"
        [route]="'https://normalnyomtav.hu'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_edilon_sedra.jpg'"
        [imageTitle]="'edilon)(sedra Bv.'"
        [route]="'https://edilonsedra.com'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_route_consult.jpg'"
        [imageTitle]="'Route Consult Bt.'"
        [route]="'https://routeconsult.hu'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_borsestarsa.jpg'"
        [imageTitle]="'Bors és Társa Bt.'"
        [route]="'https://borsestarsa.hu'"
      >
      </rs-partner-logo>
    </div>
  </mat-tab>
</mat-tab-group>

<rs-divider [useImage]="false"></rs-divider>

<div class="mat-elevation-z5 embed-container">
  <iframe src="https://www.youtube.com/embed/5a465MMPYOA" allowfullscreen></iframe>
</div>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Rail System'"
  [imageTitle]="'Rail System típusú vasúti útátjáró'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_02.jpg'"
  [subHeader]="'VASUTI-UTATJARO.TIPUS' | translate"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'edilon)(sedra'"
  [imageTitle]="'edilon)(sedra típusú vasúti útátjáró'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_03.jpg'"
  [subHeader]="'VASUTI-UTATJARO.TIPUS' | translate"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Szeged'"
  [imageTitle]="'Szeged, 2014'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_04.jpg'"
  [subHeader]="'2014'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Csorna'"
  [imageTitle]="'Csorna, 2014'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_05.jpg'"
  [subHeader]="'2012'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Lakitelek'"
  [imageTitle]="'Lakitelek, 2015'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_06.jpg'"
  [subHeader]="'2015'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Kisszállás'"
  [imageTitle]="'Kisszállás, 2015'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_01.jpg'"
  [subHeader]="'2015'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Mélykút'"
  [imageTitle]="'Mélykút, 2015'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_07.jpg'"
  [subHeader]="'2015'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Körösladány'"
  [imageTitle]="'Körösladány, 2020'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_08.jpg'"
  [subHeader]="'2020'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Lakitelek'"
  [imageTitle]="'Lakitelek, 2020'"
  [imageUrl]="'/assets/img/vasuti_utatjaro_09.jpg'"
  [subHeader]="'2020'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>
