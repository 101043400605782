import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MatTabsModule } from '@angular/material/tabs';

import { TranslateModule } from '@ngx-translate/core';

import { ImageHeaderComponent } from 'shared/image-header';
import { DividerComponent } from 'shared/divider';
import { ImageZoomComponent } from 'shared/image-zoom';

@Component({
  selector: 'rs-egyeb-munkak',
  templateUrl: './egyeb-munkak.component.html',
  styleUrls: ['./egyeb-munkak.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DividerComponent, ImageHeaderComponent, ImageZoomComponent, MatTabsModule, TranslateModule],
})
export class EgyebMunkakComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'title', content: 'Egyéb munkák' });
    this.metaService.updateTag({
      name: 'description',
      content: 'Egyéb mérnöki munkák tervezése és kivitelezése.',
    });
    this.metaService.updateTag({
      name: 'site',
      content: 'Rail System | Egyéb munkák',
    });

    this.titleService.setTitle('Egyéb munkák');
  }
}
