<div>
  <ng-container *ngIf="useImage; else emptyImage">
    <img class="columns_rebar" alt="rebar" src="/assets/img/rail_system_betonvas.png" />
    <img class="columns_rebar" alt="rebar" src="/assets/img/rail_system_betonvas.png" />
    <img class="columns_rebar" alt="rebar" src="/assets/img/rail_system_betonvas.png" />
  </ng-container>

  <ng-template #emptyImage>
    <div class="columns_rebar"></div>
  </ng-template>
</div>
