import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import { TranslateModule } from '@ngx-translate/core';

import { DividerComponent } from 'shared/divider';
import { ImageHeaderComponent } from 'shared/image-header';
import { ImageZoomComponent } from 'shared/image-zoom';
import { PartnerLogoComponent } from 'shared/partner-logo';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'rs-impresszum',
  templateUrl: './impresszum.component.html',
  styleUrls: ['./impresszum.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DividerComponent,
    ImageHeaderComponent,
    ImageZoomComponent,
    MatIconModule,
    MatListItem,
    MatSidenavModule,
    MatToolbarModule,
    MatNavList,
    MatTabsModule,
    PartnerLogoComponent,
    TranslateModule,
  ],
})
export class ImpresszumComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'title', content: 'Impresszum' });
    this.metaService.updateTag({
      name: 'description',
      content: 'Impresszum, elérhetőség, partenerek és tárhely szolgáltató adatok.',
    });
    this.metaService.updateTag({
      name: 'site',
      content: 'Rail System | Impresszum',
    });

    this.titleService.setTitle('Impresszum');
  }
}
