import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MatTabsModule } from '@angular/material/tabs';

import { TranslateModule } from '@ngx-translate/core';

import { DividerComponent } from 'shared/divider';
import { ImageHeaderComponent } from 'shared/image-header';
import { ImageZoomComponent } from 'shared/image-zoom';
import { PartnerLogoComponent } from 'shared/partner-logo';

@Component({
  selector: 'rs-peronelem',
  templateUrl: './peronelem.component.html',
  styleUrls: ['./peronelem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DividerComponent,
    ImageHeaderComponent,
    ImageZoomComponent,
    MatTabsModule,
    PartnerLogoComponent,
    TranslateModule,
  ],
})
export class PeronelemComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'title', content: 'Peronelem' });
    this.metaService.updateTag({
      name: 'description',
      content: 'Támfal-, platform- és peronelemek beépítése.',
    });
    this.metaService.updateTag({
      name: 'site',
      content: 'Rail System | Peronelem',
    });

    this.titleService.setTitle('Peronelem');
  }
}
