<rs-image-header
  [headerTitle]="'GLOBAL.BUSZOBOL' | translate"
  [imageTitle]="'Buszöböl'"
  [imageUrl]="'/assets/img/buszobol_00.jpg'"
>
</rs-image-header>

<rs-divider [useImage]="false"></rs-divider>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center" mat-stretch-tabs="false" class="mat-elevation-z2">
  <mat-tab [label]="'GLOBAL.LEIRAS' | translate" i18n="@@leiras">
    <p>{{ 'BUSZOBOL.LEIRAS-00' | translate }}</p>
    <p>{{ 'BUSZOBOL.LEIRAS-01' | translate }}</p>
  </mat-tab>
  <mat-tab [label]="'GLOBAL.ALKALMAZAS' | translate" i18n="@@alkalmazas">
    <p>{{ 'BUSZOBOL.ALKALMAZAS' | translate }}</p>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.ELONYEI' | translate" i18n="@@elonyei">
    <div>
      <div>
        <h4>{{ 'BUSZOBOL.ELONYEI-00' | translate }}</h4>
        <p>{{ 'BUSZOBOL.ELONYEI-01' | translate }}</p>
      </div>
      <div>
        <h4>{{ 'BUSZOBOL.ELONYEI-02' | translate }}</h4>
        <p>{{ 'BUSZOBOL.ELONYEI-03' | translate }}</p>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.PARTNEREK' | translate" i18n="@@partnerek">
    <div class="partner-logo">
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_ferrobeton.jpg'"
        [imageTitle]="'Ferrobeton Zrt.'"
        [route]="'https://ferrobeton.hu'"
      >
      </rs-partner-logo>
    </div>
  </mat-tab>
</mat-tab-group>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Dunaújváros'"
  [imageTitle]="'Dunaújváros, 2013'"
  [imageUrl]="'/assets/img/buszobol_01.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Budapest'"
  [imageTitle]="'Budapest, 2015'"
  [imageUrl]="'/assets/img/buszobol_02.jpg'"
  [subHeader]="'2015'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>
