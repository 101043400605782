<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" #sidenav mode="over" role="navigation">
    <mat-nav-list class="sidenav-list" (click)="sidenav.toggle()">
      <a class="sidenav-list-item" mat-list-item>
        <mat-icon class="sidenav-list-icon" matListItemIcon>close</mat-icon>
      </a>
      <a mat-list-item> </a>
      <a
        class="menu-logo sidenav-list-item"
        mat-list-item
        routerLink="/kezdolap"
        routerLinkActive="active-link"
        title="Rail System Kezdőlap"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>home</mat-icon>
        <span>Rail System</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        routerLink="/vasuti-utatjaro"
        routerLinkActive="active-link"
        title="Vasúti útátjáró"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>view_day</mat-icon>
        <span i18n="@@vasuti-utatjaro">{{ 'GLOBAL.VASUTI-UTATJARO' | translate }}</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        routerLink="/keretelem"
        routerLinkActive="active-link"
        title="Keretelem"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>view_array</mat-icon>
        <span i18n="@@keretelem">{{ 'GLOBAL.KERETELEM' | translate }}</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        routerLink="/bordas-utofeszitett-lemez"
        routerLinkActive="active-link"
        title="Bordás utófeszített lemez"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>clear_all</mat-icon>
        <span i18n="@@bordas-utofeszitett-lemez">{{ 'GLOBAL.BORDAS-UTOFESZITETT-LEMEZ' | translate }}</span>
      </a>
      <a class="sidenav-list-item" mat-list-item routerLink="/buszobol" routerLinkActive="active-link" title="Buszöböl">
        <mat-icon class="sidenav-list-icon" matListItemIcon>directions_bus</mat-icon>
        <span i18n="@@buszobol">{{ 'GLOBAL.BUSZOBOL' | translate }}</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        routerLink="/bordas-kiegyenlito-lemez"
        routerLinkActive="active-link"
        title="Bordás utófeszített lemez"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>settings_ethernet</mat-icon>
        <span i18n="@@bordas-kiegyenliito-lemez">{{ 'GLOBAL.BORDAS-KIEGYENLIITO-LEMEZ' | translate }}</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        routerLink="/peronelem"
        routerLinkActive="active-link"
        title="Peronelem"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>tab</mat-icon>
        <span i18n="@@peronelem">{{ 'GLOBAL.PERONELEM' | translate }}</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        routerLink="/egyeb-munkak"
        routerLinkActive="active-link"
        title="Egyéb munkák"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>dashboard</mat-icon>
        <span i18n="@@egyeb-munkak">{{ 'GLOBAL.EGYEB-MUNKAK' | translate }}</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        routerLink="/impresszum"
        routerLinkActive="active-link"
        title="Impresszum"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>help_outline</mat-icon>
        <span i18n="@@impresszum">{{ 'GLOBAL.IMPRESSZUM' | translate }}</span>
      </a>
      <a
        class="sidenav-list-item"
        mat-list-item
        href="https://www.youtube.com/@railsystem5090/featured"
        routerLinkActive="active-link"
        title="Video"
      >
        <mat-icon class="sidenav-list-icon" matListItemIcon>video_library</mat-icon>
        <span i18n="@@video">{{ 'GLOBAL.VIDEO' | translate }}</span>
      </a>
      <a class="sidenav-list-item" mat-list-item title="International">
        <mat-icon class="sidenav-list-icon" matListItemIcon>language</mat-icon>
        <button class="language-button" mat-flat-button (click)="useLanguage('hu')">
          <img src="assets/img/flag/hu.png" height="16" width="28" alt="Magyar" title="Magyar" />
        </button>
        <button class="language-button" mat-flat-button (click)="useLanguage('en')">
          <img src="assets/img/flag/en.png" height="16" width="28" alt="English" title="English" />
        </button>
        <button class="language-button" mat-flat-button (click)="useLanguage('ro')">
          <img src="assets/img/flag/ro.png" height="16" width="28" alt="Română" title="Română" />
        </button>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <div class="my-overlay" *ngIf="showOverlay">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </div>
    <mat-toolbar class="sidenav-toolbar">
      <button class="sidenav-toolbar-open-button" mat-stroked-button (click)="sidenav.toggle()">
        Menu
        <mat-icon class="sidenav-toolbar-open-button-icon" aria-label="Menu icon">view_day</mat-icon>
      </button>
    </mat-toolbar>

    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
