import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rs-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ImageZoomComponent {
  @Input() header = '';
  @Input() imageTitle = '';
  @Input() imageUrl = '';
  @Input() subHeader = '';
}
