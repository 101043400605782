<rs-image-header
  [headerTitle]="'GLOBAL.KERETELEM' | translate"
  [imageTitle]="'Keretelem'"
  [imageUrl]="'/assets/img/keretelem_00.jpg'"
  [subHeaderTitle]="'GLOBAL.ALULJARO' | translate"
>
</rs-image-header>

<rs-divider [useImage]="false"></rs-divider>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center" mat-stretch-tabs="false" class="mat-elevation-z2">
  <mat-tab [label]="'GLOBAL.LEIRAS' | translate" i18n="@@leiras">
    <p>{{ 'KERETELEM.LEIRAS-00' | translate }}</p>
    <p>{{ 'KERETELEM.LEIRAS-01' | translate }}</p>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.ALKALMAZAS' | translate" i18n="@@alkalmazas">
    <p>{{ 'KERETELEM.ALKALMAZAS-00' | translate }}</p>
    <p>{{ 'KERETELEM.ALKALMAZAS-01' | translate }}</p>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.ELONYEI' | translate" i18n="@@elonyei">
    <div>
      <div>
        <h4>{{ 'KERETELEM.ELONYEI-00' | translate }}</h4>
        <p>{{ 'KERETELEM.ELONYEI-01' | translate }}</p>
      </div>
      <div>
        <h4>{{ 'KERETELEM.ELONYEI-02' | translate }}</h4>
        <p>{{ 'KERETELEM.ELONYEI-03' | translate }}</p>
      </div>
    </div>
    <div>
      <div>
        <h4>{{ 'KERETELEM.ELONYEI-04' | translate }}</h4>
        <p>{{ 'KERETELEM.ELONYEI-05' | translate }}</p>
      </div>
      <div>
        <h4>{{ 'KERETELEM.ELONYEI-06' | translate }}</h4>
        <p>{{ 'KERETELEM.ELONYEI-07' | translate }}</p>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.PARTNEREK' | translate" i18n="@@partnerek">
    <div class="partner-logo">
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_ferrobeton.jpg'"
        [imageTitle]="'Ferrobeton Zrt.'"
        [route]="'https://ferrobeton.hu'"
      >
      </rs-partner-logo>
    </div>
  </mat-tab>
</mat-tab-group>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Kisújszállás'"
  [imageTitle]="'Kisújszállás, 2013'"
  [imageUrl]="'/assets/img/keretelem_01.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Piliscsaba'"
  [imageTitle]="'Piliscsaba, 2013'"
  [imageUrl]="'/assets/img/keretelem_02.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Klotildliget'"
  [imageTitle]="'Klotildliget, 2013'"
  [imageUrl]="'/assets/img/keretelem_03.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Aquincum'"
  [imageTitle]="'Aquincum, 2013'"
  [imageUrl]="'/assets/img/keretelem_04.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Aquincum'"
  [imageTitle]="'Aquincum, 2013'"
  [imageUrl]="'/assets/img/keretelem_05.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Klotildliget'"
  [imageTitle]="'Klotildliget, 2013'"
  [imageUrl]="'/assets/img/keretelem_06.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>

<rs-image-zoom
  [header]="'Piliscsaba'"
  [imageTitle]="'Piliscsaba, 2013'"
  [imageUrl]="'/assets/img/keretelem_07.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider [useImage]="false"></rs-divider>
