import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { KezdolapComponent } from './pages/kezdolap/kezdolap.component';
import { BordasUtofeszitettLemezComponent } from './pages/bordas-utofeszitett-lemez/bordas-utofeszitett-lemez.component';
import { ImpresszumComponent } from './pages/impresszum/impresszum.component';
import { EgyebMunkakComponent } from './pages/egyeb-munkak/egyeb-munkak.component';
import { PeronelemComponent } from './pages/peronelem/peronelem.component';
import { BordasKiegyenlitoLemezComponent } from './pages/bordas-kiegyenlito-lemez/bordas-kiegyenlito-lemez.component';
import { BuszobolComponent } from './pages/buszobol/buszobol.component';
import { KeretelemComponent } from './pages/keretelem/keretelem.component';
import { VasutiUtatjaroComponent } from './pages/vasuti-utatjaro/vasuti-utatjaro.component';

const routes: Routes = [
  {
    path: 'bordas-utofeszitett-lemez',
    component: BordasUtofeszitettLemezComponent,
  },
  {
    path: 'bordas-kiegyenlito-lemez',
    component: BordasKiegyenlitoLemezComponent,
  },
  {
    path: 'buszobol',
    component: BuszobolComponent,
  },
  {
    path: 'egyeb-munkak',
    component: EgyebMunkakComponent,
  },
  {
    path: 'impresszum',
    component: ImpresszumComponent,
  },
  {
    path: 'keretelem',
    component: KeretelemComponent,
  },
  {
    path: 'kezdolap',
    component: KezdolapComponent,
  },
  {
    path: 'peronelem',
    component: PeronelemComponent,
  },
  {
    path: 'vasuti-utatjaro',
    component: VasutiUtatjaroComponent,
  },
  {
    path: '',
    redirectTo: '/kezdolap',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: KezdolapComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
