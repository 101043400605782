import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { FooterComponent } from 'shared/footer';

@Component({
  selector: 'rs-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
  standalone: true,
  imports: [
    FooterComponent,
    MatButtonModule,
    MatIconModule,
    MatListItem,
    MatNavList,
    MatSidenavModule,
    MatToolbarModule,
    NgIf,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
  ],
})
export class SideNavComponent {
  protected showOverlay = true;

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    translate.setDefaultLang('hu');
  }

  public useLanguage(language: string) {
    this.translate.use(language);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  public navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }
}
