<div class="mat-elevation-z2">
  <img [src]="imageUrl" [title]="imageTitle" [alt]="imageTitle" />
  <div class="overlay-content">
    <h1 [ngClass]="{ headerMain: headerMain }" [title]="headerTitle">
      {{ headerTitle }}
    </h1>
    <h2 *ngIf="subHeaderTitle" [title]="subHeaderTitle">
      {{ subHeaderTitle }}
    </h2>
  </div>
</div>
